// sentry.client.config.ts
;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"c0d787bf49935fec322e85b44f31114838383778"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from "@sentry/nextjs";

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  beforeSend(event) {
    if (window.location.hostname !== "localhost") {
      return null;
    }
    return event; // Send all other events
  },

  // Optional integrations
  // integrations: [
  //   Sentry.replayIntegration({
  //     maskAllText: false,
  //     blockAllMedia: false,
  //   }),
  // ],

  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  debug: false,
});

export default Sentry;
